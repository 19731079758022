import { render, staticRenderFns } from "./FullImage.vue?vue&type=template&id=9d3d6ac0&"
import script from "./FullImage.vue?vue&type=script&lang=js&"
export * from "./FullImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/vercel/path0/components/elements/Picture.vue').default,AnimationGsapRotateTranslate: require('/vercel/path0/components/elements/Animations/GsapRotateTranslate.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
